.container{
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns:1fr 3fr 1fr  ;

}
.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.wrapper>img{
    width: 29rem;
    height: 24rem; ;
    position: absolute;
     bottom: 5px; 
    border-radius: 50%;
}

.blueCircle{
    width: 35rem;
    height: 30rem;
    justify-content: space-around;
    background: #497eb2 ;
    border-radius: 50%;
    position: absolute;

    
    align-items: center;
    
}

.cart2{
    position: absolute;
    bottom: 10%;
    right: 1%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cart2>svg{
    width: 50px;
    height: 50px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid var(--black);
}

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    background: white;
    padding: 10px;
    font-size: 1rem;
    border-radius: 15px;
    box-shadow: var(--shadow1);
    font-weight: 500;
}

.signup>:first-child{
    display: block;
    width: 6rem;
}

.signup>:nth-child(2)
{
    border-radius: 50%;
    border: 4px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
    
}

.text1{
  text-transform: uppercase;
  font-size: 1.85rem;
  width: min-content;
  
  font-weight: 990; 
  color: black;
}

.text2{
    display: flex;
    flex-direction: column; 
    width: min-content;
    color: black;
}

.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}
.text2>:nth-child(2)
{
    display: block;
    font-weight: 500;
    font-size: 1.3rem;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.traffic>:nth-child(2){
    font-weight: 500;
    font-size: 1.3rem;
}

.customer{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.customer> :first-child{
    font-weight: 800;
    text-align: right;
    font-size: 2.5rem;
}

.customer>:nth-child(2){
    font-weight: 500;
    font-size: 1.3rem;

}

@media screen  and ( max-width: 856px){
.text1, .text2>span:nth-of-type(2){
    font-size: .9rem;
    text-align: justify;
}

.text2 > span:nth-of-type(1),
.traffic > span:nth-child(1),
.customer > span:nth-child(1){
    font-size: 1.5rem;
    text-align: left;
}

.blueCircle{
   position: absolute; 
    width: 70%;
    height: 100%;
}



.wrapper>img{
    top:2rem;
    width: 50%;
    height: 80%;
    
}

.container{
    grid-template-areas: 
    'left center center'
    'right right right';
}

.container>:first-child{
    grid-area: left;
    grid-template-rows: none;
    gap:8rem;
}

.container>:nth-child(2){
    grid-area: center;
}

.container>:nth-child(3){
    grid-area: right;
    display: flex;
    justify-content: space-around;
    margin: 2rem 0rem;
}


}
   

@media screen  and(max-width: 640px){
    .blueCircle{
        top:0;
        width: 16rem;
        height: 72%;
    }

    

    .wrapper>img{
        left:6rem;
        top:3rem;
        width: 12rem;
        height: 15rem;
    }

    
    
    
    
}
