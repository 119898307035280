.testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9rem 4.5rem;
    gap:3rem;

}

.wrapper{
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}

.wrapper>img{
    width: 43rem ;
    justify-self: center;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1){
    font-size: 3.5rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
}


.container>:nth-child(2){
    font-size: 1.2rem;
    text-transform: uppercase;
    display: block;
}

.wrapper>:nth-child(3)
{
    text-align: right;
}
.reviews{
    font-size: 3rem;
font-weight: bold;
text-transform: uppercase;
}

.carousal{
    width: 100%;
    
}



.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: 1.5rem;
    align-items: center;
    justify-content: center;
    padding: 3.5rem  4rem;
    gap: 0.8rem;
    
    
    
}
.testimonial>img{
    position: absolute!important ;
    width: 9rem!important;
    top:1.5rem ;
    
    border-radius:50%;
  
}
.testimonial>span:nth-of-type(1){
    align-items: center;
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin-top: 8rem;
}

.testimonial>hr{
    height: 5px;
    width: 80%;
    background: rgb(10, 10, 10);
    border: none;
    
}
.testimonial>span:nth-of-type(2){
    font-weight: 100rem;
}

@media screen and (max-width:956px) {
    .wrapper{
        grid-template-columns: 1fr;
    }

    .wrapper>img{
        width:80%;
    }

    .wrapper>:nth-child(3), .wrapper>:nth-child(1)
    {
        text-align: center;
    }

    .testimonial>img{
        
        
        top:0.8rem;

    
    }


    

    

    
    
    
    

    
}

