

:root {
  --black:#343434;
  --bg: #F8E367;
  --shadow1 : 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
  --shadow2 : 0px 13px 46px rgba(0, 0, 0, 0.13);
  --pink : #FE956F;


}
.App{
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar{
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-track{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb{
  background-color: skyblue;
  border-radius: 1ex;
}

a {
  text-decoration: none;
  color: inherit
}