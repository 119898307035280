.cFooterWrapper{
    width: 100%;
    
}

.cFooterWrapper>hr{
    width: 100%;
    height: 2px;
    border: none;
    background: rgb(9, 9, 9);
    margin-top: 0.1rem;
}

.cFooter{
    display: flex;
    width: 100%;
    
    
    justify-content: space-around;
}

.logo{
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.logo>img{
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 1.2rem;
    border: 3px solid var(--black);
}

.logo>span{
    font-weight: 600;
    font-size: 4rem;
    
}

.block{
    display: flex;
    flex-direction: column;
    
   
}

.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 1.7rem;
    gap: 0.5rem;
    font-style: normal;
    font-weight: bold;
}

.detail>span:nth-last-of-type(1)
{
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}



.pngLine:hover{
    cursor: pointer;
}

.pngLine{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon{
    width: 25px;
    height:60px;
}

.copyright{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: .65rem;
    margin-top: 2rem;
}


@media screen and (max-width:864px){
    .cFooter{
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 1.7rem;
        gap:2rem;
    }
}

@media screen and (max-width:640px){
    .cFooter{
        justify-content: center;
    }

    .logo{
        display: none;
    }
    
}