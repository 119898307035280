.container{
    padding: 2  .2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.container>:nth-child(1){
    position: absolute;
    width: 12rem;
    left: 18%;
    top: -1rem; 
}

.container>:nth-child(2){
    font-size: 3rem;
    
}

.products{
    display: grid;
    width: 92%;
    grid-template-columns: 22% auto;
}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    font-weight: bold;
    font-size: 1.7rem;
    top: 40%;
}

.menu>li:hover{
    color: var(--pink);
    cursor: pointer;
}

.lists{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 40rem;
    overflow-y: scroll;
    grid-gap: 5rem;
    justify-content: space-around;
}

.product{
    width: 15rem;
    height: 8rem;
    background-color: white;
    position:relative;
    flex-direction: column;
    font-weight: bold;
    overflow: hidden;
  
    padding: 1rem;
    display:flex;
    
    border-radius: 1rem;
    border: 4px solid var(--black);
  
}

.product.center{
    text-align: left;
    

}

.product.btn{
    border: 2px solid var(--black);
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

.product>img{
    top: 0rem;
    width: 7rem;
    height: 10rem;
}

@media screen and (max-width:856px) {
    .container{
        gap: 1rem;
    }

    .container>:nth-child(1)
    {
        display: none;
    }

    .products{
        grid-template-columns: none;
        gap:1rem;
    }

    .menu{
        flex-direction: row;
    }
    
}

@media screen  and (max-width:640px){
    .menu{
        margin-left: -2rem;
        gap: 0.5rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.1rem;
    }

    .container h1{
        text-align: center;
        font-size: 2.5rem;
    }

    .product{
        justify-content: center;
        margin-left: 2rem;
        
        
    }
    
}


